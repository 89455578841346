import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

export default function LoginSuccess() {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  console.log("msal", instance, accounts);
  useEffect(() => {
    // Handle the redirect here
    instance
      .handleRedirectPromise()
      .then((response) => {
        // If response is non-null, it means we have response from redirect
        if (response) {
          console.log("Successfully handled redirect", response);
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error handling redirect:", error);
        navigate("/login-error");
      });
  }, [instance, accounts, navigate, inProgress]);

  return (
    <div>
      <div>Successful Login!</div>
      <p>
        You should be redirected to the main page shortly. If not, click <a href="/">here</a>.
      </p>
    </div>
  );
}
