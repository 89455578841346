import { Button } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PropTypes from "prop-types";

const ClearFiltersButton = ({ hasActiveFilters, clearFilters }) => {
  if (!hasActiveFilters) {
    return null;
  }
  return (
    <Button
      variant="outlined"
      startIcon={<FilterAltOffIcon />}
      onClick={clearFilters}
      sx={{
        marginRight: "20px",
        color: "#2F94CD",
        borderColor: "#2F94CD",
        "&:hover": {
          borderColor: "#2F94CD",
          backgroundColor: "rgba(47, 148, 205, 0.04)",
        },
      }}
    >
      Clear Filters
    </Button>
  );
};

ClearFiltersButton.propTypes = {
  hasActiveFilters: PropTypes.bool.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

export default ClearFiltersButton;
