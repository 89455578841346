import PropTypes from "prop-types";

const StatusCellRenderer = (props) => {
  const getColor = (status) => {
    switch (status) {
      case "Ready":
      case "Rules Applied":
      case "Completed":
        return "#2FD92B"; // Green
      case "Error":
        return "#FF3E3E"; // Red
      case "Pending":
      case "Analysed":
      case "Results Reviewed":
      case "Report Generated":
      case "Report Exported":
        return "#FFDA56"; // Yellow
      case "New":
        return "#6BA1B9"; // Blue
      default:
        return "gray";
    }
  };
  const value = props.data[props.colDef.field];

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <span>{value}</span>
      <span
        style={{
          width: "13px",
          height: "13px",
          borderRadius: "50%",
          backgroundColor: getColor(value),
        }}
      ></span>
    </div>
  );
};

StatusCellRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatusCellRenderer;
