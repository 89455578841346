import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const OverrideResultsButtonRenderer = ({ data, onClick, disabled }) => {
  const handleClick = (event) => {
    onClick(event, data);
  };

  return (
    <Button sx={{ textTransform: "none" }} disabled={disabled} onClick={handleClick}>
      Override
    </Button>
  );
};

OverrideResultsButtonRenderer.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default OverrideResultsButtonRenderer;
