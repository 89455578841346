import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Typography, CircularProgress, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import useJobLoader from "../customHooks/useJobLoader";
import useJobCreationMutation from "../customHooks/useJobCreationMutation";
import FormRowGenerator from "../components/FormRowGenerator";
import { useNavigate } from "react-router-dom";
import useAuthenticatedPage from "../customHooks/useAuthenticatedPage";

/**
 * CreateNewJob Component
 *
 * This component provides a form interface for creating new jobs in the policy checker system.
 * It handles the collection and submission of job-related data including client information,
 * business details, and associated files.
 *
 * Features:
 * - Dynamic form generation for static and scenario-specific fields
 * - File upload handling for multiple documents
 * - Form validation and error handling
 * - Automatic navigation after successful submission
 *
 * @component
 * @returns {JSX.Element} A form interface for creating new jobs
 */
export default function CreateNewJob() {
  const { jobKey } = useParams();
  const jobLoader = useJobLoader(jobKey);
  const navigate = useNavigate();
  const { shouldBlockPage, userData } = useAuthenticatedPage();
  const jobCreation = useJobCreationMutation();
  const [submitError, setSubmitError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Static form fields configuration
   * Each field object defines the properties required for form rendering and validation
   */
  const staticFields = [
    {
      name: "lob_id",
      label: "Line of Business",
      type: "lob",
      defaultValue: null,
      required: true,
    },
    {
      name: "issuer",
      label: "Issuing Company",
      type: "issuer",
      defaultValue: null,
      required: true,
    },
    {
      name: "client_code",
      label: "Client Code",
      type: "text",
      defaultValue: null,
      required: true,
    },
    {
      name: "account_name",
      label: "Account Name",
      type: "text",
      defaultValue: null,
      required: true,
    },
    {
      name: "account_manager",
      label: "Account Manager",
      type: "text",
      defaultValue: null,
      required: true,
    },
    {
      name: "frp_office",
      label: "FRP Office",
      type: "text",
      defaultValue: null,
      required: true,
    },
    {
      name: "user_submitted",
      label: "User Submitted",
      type: "text",
      defaultValue: userData?.username,
      disabled: userData?.username !== undefined,
      required: true,
    },
  ];

  /**
   * Form configuration using react-hook-form
   * Defines the default values for the form fields
   */
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      lob_id: null,
      client_code: null,
      account_name: null,
      frp_office: null,
      user_submitted: userData?.username,
      issuer: null,
      file_1: null,
      file_2: null,
      file_3: null,
      file_4: null,
      file_5: null,
      file_6: null,
      file_7: null,
      file_8: null,
      file_9: null,
      file_10: null, //TODO: This should be dynamic
    },
  });

  /**
   * Form submission handler
   * Collects the form data, constructs the submission payload, and triggers the job creation mutation
   *
   * @param {object} data - Form data collected by react-hook-form
   */
  const onSubmit = async (data) => {
    setIsLoading(true);
    setSubmitError(null);
    clearErrors("files");

    const selectedFiles = Object.keys(data).filter((key) => key.startsWith("file_") && data[key]).length;
    if (selectedFiles < 2) {
      setError("files", {
        type: "manual",
        message: "At least 2 files must be selected",
      });
      setIsLoading(false);
      return;
    }

    const submitData = {
      scenario_id: jobKey,
      client_code: data.client_code,
      account_name: data.account_name,
      account_manager: data.account_manager,
      user_submitted: data.user_submitted,
      frp_office: data.frp_office,
      lob_id: data.lob_id,
      issuer_id: data.issuer,
      files: [],
    };

    jobLoader.data.fields.forEach((field) => {
      const key = "file_" + field.scenario_file_id;
      if (data[key]) {
        submitData.files.push({
          file_name: data[key].name,
          scenario_file_id: field.scenario_file_id,
          file_content: data[key],
        });
      }
    });

    console.log(submitData);
    jobCreation
      .mutateAsync(submitData)
      .then((resp) => {
        console.log(resp, "job created");
        if (resp.status === "error") {
          setSubmitError(resp.message || "An error occurred while creating the job");
        } else {
          navigate(`/`);
        }
      })
      .catch((error) => {
        setSubmitError(error.message || "An error occurred while creating the job");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (shouldBlockPage) {
    return <div>Invalid access</div>;
  }

  /**
   * Render a loading indicator while the job data is being loaded
   */
  if (jobLoader.isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  /**
   * Render an error message if the job data loading fails
   */
  if (jobLoader.isError || !jobLoader.data) {
    return (
      <Box sx={{ margin: "50px 20px" }}>
        <Alert severity="error">{jobLoader.error?.message || "Error loading job data. Please try again later."}</Alert>
      </Box>
    );
  }

  const { title, fields } = jobLoader.data;

  /**
   * Main form render
   * Displays the job creation form with:
   * - Job title
   * - Static fields (client info, business details)
   * - Dynamic fields from the job template
   * - Submit button with loading state
   * - Error messages if submission fails
   */
  return (
    <Box sx={{ margin: "50px 20px", textAlign: "left" }}>
      <Typography variant="h5" sx={{ textDecoration: "underline", marginBottom: "20px" }}>
        {title}
      </Typography>

      <Box sx={{ border: "2px solid #2F94CD", padding: "20px", flexDirection: "column", display: "flex", gap: "10px" }}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {/* Render static form fields */}
          {staticFields?.map((field) => (
            <FormRowGenerator key={field.name} thisField={field} control={control} errors={errors} />
          ))}
          {/* Render dynamic form fields from job template */}
          {fields?.map((field) => (
            <FormRowGenerator key={field.name} thisField={field} control={control} errors={errors} />
          ))}

          {errors.files && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errors.files.message}
            </Alert>
          )}
          {submitError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}

          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isLoading ? "Submitting..." : "Confirm"}
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              navigate(`/`);
            }}
          >
            Cancel
          </Button>
        </form>
      </Box>
    </Box>
  );
}
