import mockData from "./mocks/job-data.js";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_BACKEND_URL, REACT_APP_USE_MOCKED_BACKEND } = getEnvVars();

const fetchJobFiles = async (jobId, token) => {
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/job-details-by-jobid?job_id=${jobId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data.data;
};

const fetchJobComparisons = async (jobId, token) => {
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/job-comparisions-by-jobid?job_id=${jobId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data.data;
};

const fetchJobLogs = async (jobId, token) => {
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/job-logs-by-jobid?job_id=${jobId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data.data;
};

const getClientDataByJobId = async (jobId, token = "") => {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    const md = mockData(jobId);
    return Promise.resolve(md);
  }
  try {
    const [files, comparisons, logs] = await Promise.all([
      fetchJobFiles(jobId, token),
      fetchJobComparisons(jobId, token),
      fetchJobLogs(jobId, token),
    ]);

    return {
      jobId,
      files,
      comparisons,
      logs,
      status: logs[0]?.status || "Unknown",
    };
  } catch (error) {
    console.error("Error fetching job data:", error);
    throw error;
  }
};

export default getClientDataByJobId;
