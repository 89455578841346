import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";
import useIssuer from "../customHooks/useIssuer";

const IssuerSelect = React.forwardRef(function IssuerSelect(props, ref) {
  const { isLoading, isError, data = { data: [] } } = useIssuer();

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>Error</p>;
  }

  return (
    <Select {...props} value={props.value ?? ""} ref={ref} fullWidth labelId="issuer-select-label" label={props.label}>
      {(data?.data || []).map((option) => (
        <MenuItem key={option.issuer_id} value={option.issuer_id}>
          {option.issuer_name}
        </MenuItem>
      ))}
    </Select>
  );
});

IssuerSelect.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
};

export default IssuerSelect;
