import { useMutation, useQueryClient } from "@tanstack/react-query";
import getEnvVars from "../utils/getEnvVars";
import useAccessToken from "./useAccessToken";

/**
 * Custom hook that provides mutation functionality for updating comparison results.
 * This hook uses React Query's useMutation to handle the state and lifecycle of server mutations.
 *
 * @returns {Object} A mutation object with the following properties:
 * @returns {Function} .mutate - Function to trigger the mutation. Accepts an object with:
 *   @param {string} id - The unique identifier of the results to update
 *   @param {boolean} result - The reviewed result value
 *   @param {string} notes - Review notes for the comparison
 * @returns {boolean} .isLoading - Whether the mutation is currently in progress
 * @returns {Object} .error - Error object if the mutation failed
 * @returns {Object} .data - Data returned from the successful mutation
 *
 * When using mocked backend (REACT_APP_USE_MOCKED_BACKEND=true), returns mock success response.
 * Otherwise, sends POST request to the backend API to update results.
 */
const useComparisonOverrideMutator = () => {
  const queryClient = useQueryClient();
  const accessTokenPromise = useAccessToken().getToken();
  const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

  return useMutation({
    mutationFn: async ({ id, result, notes, username }) => {
      if (REACT_APP_USE_MOCKED_BACKEND === "true") {
        return {
          status: "success",
          message: "Results updated successfully.",
          results_id: id,
          reviewed_result: false,
        };
      }
      const accessToken = await accessTokenPromise;
      return fetch(`${REACT_APP_BACKEND_URL}api/update-results`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          results_id: id,
          reviewed_result: result,
          review_user: username,
          review_notes: notes,
        }),
      }).then((response) => {
        queryClient.invalidateQueries({ queryKey: ["result"] });
        return response.json();
      });
    },
  });
};

export default useComparisonOverrideMutator;
