import { useState, useCallback, useEffect } from "react";

/**
 * Custom hook to manage grid filters state and initialization
 * @param {Object} gridRef - Reference to the AG Grid instance
 * @returns {Object} Object containing filter state and handlers
 */
export const useGridFilters = (gridRef) => {
  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);

  const checkFilters = useCallback(() => {
    if (!gridRef?.current?.api) {
      return;
    }

    try {
      const filterModel = gridRef.current.api.getFilterModel() || {};
      const filterCount = Object.keys(filterModel).length;
      setHasActiveFilters(filterCount > 0);
    } catch (error) {
      setHasActiveFilters(false);
    }
  }, [gridRef]);

  const handleGridReady = useCallback(() => {
    setIsGridReady(true);
  }, []);

  const clearFilters = useCallback(() => {
    try {
      if (!gridRef?.current?.api) {
        return;
      }
      gridRef.current.api.setFilterModel(null);
    } catch (error) {
      // Silent fail as this is a UI enhancement
    }
  }, [gridRef]);

  useEffect(() => {
    if (!isGridReady || !gridRef?.current?.api) {
      return;
    }

    const gridApi = gridRef.current.api;
    gridApi.addEventListener("filterChanged", checkFilters);

    return () => {
      if (gridApi) {
        gridApi.removeEventListener("filterChanged", checkFilters);
      }
    };
  }, [isGridReady, gridRef, checkFilters]);

  return {
    hasActiveFilters,
    clearFilters,
    handleGridReady,
  };
};
