import mockData from "./mocks/issuer-list";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

export default async function getIssuerList(token = "") {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData);
  }
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/issuers?`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
}
