/**
 * PolicyCheckerHome Component
 *
 * Main page component for the Policy Checker application that displays active checks in a grid format.
 * Features include:
 * - Authentication check and redirection
 * - Loading and error states
 * - Interactive data grid with row click navigation
 * - Tab navigation and action buttons
 *
 * @component
 * @returns {JSX.Element} The rendered PolicyCheckerHome component
 */
import PolicyCheckerAgGrid from "../ag-grid/PolicyCheckerAgGrid";
import { Box, CircularProgress, Typography } from "@mui/material";
import useActiveChecks from "../customHooks/useActiveChecks";
import { useNavigate } from "react-router-dom";
import ActionButton from "../components/ActionButton";
import TabButtons from "../components/TabButtons";
import useAuthenticatedPage from "../customHooks/useAuthenticatedPage";
import { useGridFilters } from "../customHooks/useGridFilters";

import { usePolicyCheckerHomeColumnDefs } from "../customHooks/columnDefs/usePolicyCheckerHomeColumnDefs";
import { useRef } from "react";
import ClearFiltersButton from "../components/ClearFiltersButton";
import ErrorBoundary from "../components/ErrorBoundary";

const PolicyCheckerHome = () => {
  const { data, isLoading, error } = useActiveChecks();

  const navigate = useNavigate();
  const policyCheckerHomeColumnDefs = usePolicyCheckerHomeColumnDefs();
  const { shouldBlockPage } = useAuthenticatedPage();
  const gridRef = useRef(null);
  const { hasActiveFilters, clearFilters, handleGridReady } = useGridFilters(gridRef);

  if (shouldBlockPage) {
    return <div>Invalid access</div>;
  }

  const onRowClicked = (event) => {
    const jobId = event.data.job_id;
    navigate(`/jobdetails/${jobId}`);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">Error loading data: {error.message}</Typography>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 20px 0 20px",
        }}
      >
        <TabButtons />
        <div style={{ display: "flex", alignItems: "center" }}>
          <ClearFiltersButton hasActiveFilters={hasActiveFilters} clearFilters={clearFilters} />
          <ActionButton />
        </div>
      </div>
      <Box sx={{ border: "2px solid #2F94CD", margin: "0px 20px" }}>
        <ErrorBoundary>
          <div
            className={"ag-theme-quartz"}
            style={{ height: 655, borderRadius: "5px", margin: "10px auto", padding: "15px" }}
          >
            <PolicyCheckerAgGrid
              gridRef={gridRef}
              rowData={data}
              columnDefs={policyCheckerHomeColumnDefs}
              onRowClicked={onRowClicked}
              onGridReady={handleGridReady}
            />
          </div>
        </ErrorBoundary>
      </Box>
    </>
  );
};

export default PolicyCheckerHome;
