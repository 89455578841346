import { useEffect, useState } from "react";
import { Modal, Box, Typography, RadioGroup, FormControlLabel, Radio, TextField, Button, Stack } from "@mui/material";
import PropTypes from "prop-types";
import useComparisonOverrideMutator from "../customHooks/useComparisonOverrideMutator";
import useAuthenticatedPage from "../customHooks/useAuthenticatedPage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

/**
 * OverrideModal is a component that allows users to update check results by overriding
 * the existing match/discrepancy status and adding additional notes.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.overrideData - Data for the override operation
 * @param {boolean} props.overrideData.result - Current result status (true for match, false for discrepancy)
 * @param {string} props.overrideData.results_id - Unique identifier for the result being overridden
 * @param {Function} props.setOverrideData - Function to update the override data state
 * @returns {React.Component} A modal dialog for updating check results
 */
const OverrideModal = ({ overrideData, setOverrideData }) => {
  const [result, setResult] = useState(overrideData?.checkpoint_result ? "match" : "discrepancy");
  const [notes, setNotes] = useState("");
  const { userData } = useAuthenticatedPage();

  useEffect(() => {
    if (overrideData) {
      setResult(overrideData?.checkpoint_result ? "match" : "discrepancy");
      setNotes("");
    }
  }, [overrideData]);

  // Custom hook for handling the override mutation
  const comparisonOverrideMutator = useComparisonOverrideMutator();

  /**
   * Closes the modal by resetting the override data
   */
  const handleClose = () => {
    setOverrideData(null);
  };

  /**
   * Submits the override request with updated result and notes
   * After successful submission, closes the modal
   */
  const handleSubmit = () => {
    comparisonOverrideMutator
      .mutateAsync({
        id: overrideData?.results_id,
        result: result === "match",
        notes,
        username: userData?.username || "",
      })
      .then((res) => {
        console.log("OverrideModal.res", res);
        handleClose();
      });
  };

  if (!overrideData) return null;

  return (
    <Modal
      open={overrideData !== null}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Update check results
        </Typography>

        <RadioGroup value={result} onChange={(e) => setResult(e.target.value)} sx={{ mb: 2 }}>
          <FormControlLabel value="match" control={<Radio />} label="Match" />
          <FormControlLabel value="discrepancy" control={<Radio />} label="Discrepancy" />
        </RadioGroup>

        <TextField
          fullWidth
          multiline
          rows={4}
          label="Additional notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          sx={{ mb: 3 }}
        />

        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

OverrideModal.propTypes = {
  overrideData: PropTypes.shape({
    result: PropTypes.bool,
    results_id: PropTypes.number.isRequired,
    checkpoint_result: PropTypes.bool,
  }),
  setOverrideData: PropTypes.func.isRequired,
};

export default OverrideModal;
