import { Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import useEnvVars from "../../customHooks/useEnvVars";
import useAccessToken from "../../customHooks/useAccessToken";

const DownloadReportButtonRenderer = (props) => {
  const envVars = useEnvVars();
  const token = useAccessToken().getToken();
  const comparisonId = props.data?.comparison_id || props.value;
  const fileName = props.data?.report_name || "Download report";

  const handleClick = async () => {
    if (comparisonId) {
      fetch(`${envVars.REACT_APP_BACKEND_URL}api/report-by-comparisonid?comparison_id=${comparisonId}`, {
        headers: {
          Authorization: `Bearer ${await token}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success" && result.data) {
            const { report_name, file_content } = result.data;
            const decodedContent = atob(file_content);
            const blob = new Blob([decodedContent], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = report_name;
            link.click();
            window.URL.revokeObjectURL(url);
          }
        })
        .catch((error) => {
          console.error("Error downloading report:", error);
        });
    }
  };

  if (!props.data?.report_name) return null;

  return (
    <>
      <Button sx={{ textTransform: "none" }} disabled={!props.data?.report_name} onClick={handleClick}>
        {fileName}
      </Button>
    </>
  );
};

DownloadReportButtonRenderer.propTypes = {
  value: PropTypes.string,
  data: PropTypes.shape({
    comparison_id: PropTypes.string,
    report_name: PropTypes.string,
  }),
};

export default DownloadReportButtonRenderer;
