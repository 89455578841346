import mockData from "./mocks/job-files-by-comparisonid";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

const fetchFilesByJobId = async (jobId, token = "") => {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData.data);
  }
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/job-files-by-comparisonid?comparison_id=${jobId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data.data;
};

export default fetchFilesByJobId;
