import PolicyCheckerHome from "./pages/PolicyCheckerHome";
import Root from "./layouts/Root";
import JobDetails from "./pages/JobDetails";
import CreateNewJob from "./pages/CreateJob";
import Results from "./pages/Results";
import History from "./pages/History";
import JobCreationSuccess from "./pages/JobCreationSuccess";
import LoginSuccess from "./pages/LoginSuccess";
import Login from "./pages/Login";
import LoginError from "./pages/LoginError";

const routesConfig = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <PolicyCheckerHome />,
        index: true,
      },
      {
        path: "/history",
        element: <History />,
      },
      {
        path: "jobdetails/:jobId",
        element: <JobDetails />,
      },
      {
        path: "newJob/:jobKey",
        element: <CreateNewJob />,
      },
      {
        path: "jobdetails/:jobId/results/:comparisonId",
        element: <Results />,
      },
      {
        path: "jobsuccess/:jobId",
        element: <JobCreationSuccess />,
      },
      {
        path: "success",
        element: <LoginSuccess />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "login-error",
        element: <LoginError />,
      },
    ],
  },
];
export default routesConfig;
