import { useMemo } from "react";
import { detailedDateFormatter } from "../../ag-grid/valueFormatter/dateFormatter";

/**
 * Custom hook that provides column definitions for the Activity Tracker grid.
 *
 * @returns {Array} An array of column definition objects for AG Grid with the following columns:
 *   - Activity: Displays the activity description
 *   - Date: Shows the activity date with custom date formatting
 *
 * @example
 * const columnDefs = useActivityTrackerColumnDefs();
 * <AgGridReact columnDefs={columnDefs} />
 */
export const useActivityTrackerColumnDefs = () => {
  return useMemo(
    () => [
      {
        headerName: "Activity",
        field: "activity",
        headerTooltip: "Activity",
      },
      {
        headerName: "Date",
        field: "date",
        valueFormatter: detailedDateFormatter,
        headerTooltip: "Date",
      },
    ],
    []
  );
};
