import PolicyCheckerAgGrid from "../ag-grid/PolicyCheckerAgGrid";
import { Box } from "@mui/material";
import useHistoryChecks from "../customHooks/useHistoryChecks";
import { useNavigate } from "react-router-dom";
import TabButtons from "../components/TabButtons";
import { usePolicyCheckerHomeColumnDefs } from "../customHooks/columnDefs/usePolicyCheckerHomeColumnDefs";
import useAuthenticatedPage from "../customHooks/useAuthenticatedPage";
import { useRef } from "react";
import ClearFiltersButton from "../components/ClearFiltersButton";
import { useGridFilters } from "../customHooks/useGridFilters";
import ErrorBoundary from "../components/ErrorBoundary";

const HistoryPage = () => {
  const { data } = useHistoryChecks();

  const navigate = useNavigate();
  const policyCheckerHomeColumnDefs = usePolicyCheckerHomeColumnDefs();
  const { shouldBlockPage } = useAuthenticatedPage();
  const gridRef = useRef(null);
  const { hasActiveFilters, clearFilters, handleGridReady } = useGridFilters(gridRef);

  if (shouldBlockPage) {
    return <div>Invalid access</div>;
  }

  const onRowClicked = (event) => {
    const jobId = event.data.job_id;
    navigate(`/jobdetails/${jobId}`);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div className="tab-container" style={{ marginLeft: "20px" }}>
          <TabButtons />
        </div>
        <div className="button-container">
          <ClearFiltersButton hasActiveFilters={hasActiveFilters} clearFilters={clearFilters} />
        </div>
      </div>
      <Box sx={{ border: "2px solid #2F94CD", margin: "0px 20px" }}>
        <ErrorBoundary>
          <div
            className={"ag-theme-quartz"}
            style={{ height: 655, borderRadius: "5px", margin: "10px auto", padding: "15px" }}
          >
            <PolicyCheckerAgGrid
              gridRef={gridRef}
              columnDefs={policyCheckerHomeColumnDefs}
              rowData={data || []}
              onRowClicked={onRowClicked}
              onGridReady={handleGridReady}
            />
          </div>
        </ErrorBoundary>
      </Box>
    </>
  );
};

export default HistoryPage;
