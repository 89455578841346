import mockData from "./mocks/jobs-complete.js";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

const fetchHistoryChecks = async (token = "") => {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockData.data);
      }, 1000);
    });
  }
  const response = await fetch(`${REACT_APP_BACKEND_URL}api/jobs?status=COMPLETE`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const result = await response.json();
  return result.data;
};
export default fetchHistoryChecks;
