import { Typography, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Paper } from "@mui/material";
import PropTypes from "prop-types";

/**
 * InformationContainer displays job-related information in a table format with a styled header row
 * matching the AG Grid Quartz theme. The component dynamically renders columns based on available data,
 * omitting any columns where values are not provided.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.clientName - Name of the client, displayed in the "Client Name" column
 * @param {string} props.clientCode - Client's identification code, displayed in the "Client Code" column
 * @param {string} [props.lineOfBusiness] - Line of business associated with the job
 * @param {string} [props.jobId] - Unique identifier for the job
 * @param {string} [props.comparisonId] - Unique identifier for the comparison
 * @param {string} [props.comparisonName] - Name of the comparison
 * @returns {JSX.Element} A Material-UI table displaying job information with AG Grid Quartz styling
 *
 * @example
 * <InformationContainer
 *   clientName="Acme Corp"
 *   clientCode="AC123"
 *   lineOfBusiness="Insurance"
 *   jobId="JOB-001"
 *   comparisonId="CMP-001"
 *   comparisonName="Comparison 1"
 * />
 */
const InformationContainer = ({
  clientCode,
  //  scenarioName,
  lineOfBusiness,
  jobId,
  comparisonId,
  comparisonName,
}) => {
  const columns = [
    { id: "clientCode", label: "Client Code", value: clientCode },
    { id: "jobId", label: "Job ID", value: jobId },
    { id: "comparisonId", label: "Comparison ID", value: comparisonId },
    { id: "lineOfBusiness", label: "Line of Business", value: lineOfBusiness },
    { id: "comparisonName", label: "Comparison Name", value: comparisonName },
  ].filter((column) => column.value);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: "none",
        borderRadius: 0,
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#00203d",
              height: "30px",
              "&:hover": {
                backgroundColor: "#00203d",
              },
            }}
          >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  color: "#fff",
                  borderBottom: "none",
                  padding: "0 8px",
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  borderBottom: "none",
                  padding: "8px",
                }}
              >
                <Typography>{column.value}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InformationContainer.propTypes = {
  clientCode: PropTypes.string.isRequired,
  scenarioName: PropTypes.string.isRequired,
  lineOfBusiness: PropTypes.string,
  jobId: PropTypes.string,
  client: PropTypes.string,
  comparisonId: PropTypes.string,
  comparisonName: PropTypes.string,
};

export default InformationContainer;
