import { useMemo } from "react";
import StatusCellRenderer from "../../ag-grid/cellRenderer/StatusCellRenderer";
import CustomFilter from "../../ag-grid/customFilter/customFilter";
import dateFormatter from "../../ag-grid/valueFormatter/dateFormatter";

/**
 * Custom hook that provides column definitions for the Policy Checker Home grid.
 *
 * This hook uses useMemo to optimize performance by memoizing the column definitions.
 * Each column is configured with appropriate filtering, sorting, and display options
 * to create a comprehensive and interactive grid view.
 *
 * @returns {function} A memoized function that returns an array of column definitions with the following columns:
 *   - Job Id: Unique identifier for each job
 *   - Client Code: Client identifier code
 *   - Client: Client name
 *   - Line of Business: Business category
 *   - Issuing Company: Name of the issuer
 *   - Account Manager: Manager name
 *   - Last Updated By: User who last modified the job
 *   - Checks: Scenario name with custom filter
 *   - Status: Job status with custom renderer
 *   - Date Created: Creation date with custom formatting (default desc sort)
 *
 * Each column includes:
 *   - Filtering capabilities (agTextColumnFilter or custom)
 *   - Sorting enabled
 *   - Tooltip support where applicable
 *   - Custom renderers and formatters for specific columns
 *
 * @example
 * // In your component:
 * const columnDefs = usePolicyCheckerHomeColumnDefs();
 *
 * // Use in AG Grid:
 * <AgGridReact columnDefs={columnDefs()} />
 */
export const usePolicyCheckerHomeColumnDefs = () => {
  return useMemo(
    () => [
      {
        field: "job_id",
        headerName: "Job Id",
        filter: "agTextColumnFilter",
        sortable: true,
        headerTooltip: "Job Id",
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "client_code",
        headerName: "Client Code",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Client Code",
      },
      {
        field: "account_name",
        headerName: "Account",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Account",
      },
      {
        field: "lob_name",
        headerName: "Line of Business",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Line of Business",
      },
      {
        field: "issuer_name",
        headerName: "Issuing Company",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Issuing Company",
      },
      {
        field: "account_manager",
        headerName: "Account Manager",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Account Manager",
      },

      {
        field: "scenario_name",
        headerName: "Checks",
        filter: CustomFilter,
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Checks",
      },
      {
        field: "status",
        headerName: "Status",
        filter: "agTextColumnFilter",
        sortable: true,
        cellRenderer: StatusCellRenderer,
        maxWidth: 170,
        minWidth: 170,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Status",
      },
      {
        field: "created_at",
        headerName: "Date Created",
        filter: "agDateColumnFilter",
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset", "apply"],
        },
        sortable: true,
        sort: "desc",
        cellDataType: "dateString",
        valueFormatter: dateFormatter,
        headerTooltip: "Date Created",
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "user_submitted",
        headerName: "Last Updated By",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Last Updated By",
      },
    ],
    []
  );
};
