import { useMemo } from "react";
import ViewResultsButtonRenderer from "../../ag-grid/cellRenderer/ViewResultsButtonRenderer";
import DownloadReportButtonRenderer from "../../ag-grid/cellRenderer/DownloadReportButtonRenderer";

/**
 * Custom hook that provides column definitions for the Comparison grid.
 *
 * This hook defines columns for displaying file comparison information and includes
 * a custom button renderer for viewing comparison results.
 *
 * @returns {Array} An array of column definition objects for AG Grid with the following columns:
 *   - Comparison Name: Name of the comparison
 *   - First File: Name of the first file being compared
 *   - Second File: Name of the second file being compared
 *   - View Results: Button column with custom renderer for viewing comparison results
 *
 * @example
 * const columnDefs = useComparisonColumnDefs();
 * <AgGridReact columnDefs={columnDefs} />
 */
export const useComparisonColumnDefs = () => {
  return useMemo(
    () => [
      { headerName: "Comparison Name", field: "comparisonName", headerTooltip: "Comparison Name" },
      { headerName: "First File", field: "firstFileName", headerTooltip: "First File" },
      { headerName: "Second File", field: "secondFileName", headerTooltip: "Second File" },
      { headerName: "Status", field: "comparisonStatus", headerTooltip: "Status" },
      {
        headerName: "View results",
        field: "comparisonId",
        cellRenderer: ViewResultsButtonRenderer,
        headerTooltip: "View results",
        width: "150px",
      },
      {
        headerName: "Report",
        field: "comparisonId",
        cellRenderer: DownloadReportButtonRenderer,
        headerTooltip: "Report",
      },
    ],
    []
  );
};

export default useComparisonColumnDefs;
