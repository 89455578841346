import { useQuery } from "@tanstack/react-query";
import fetchFilesByComparisonId from "../services/get-files-by-comparisonid";
import useAccessToken from "./useAccessToken";

/**
 * Custom hook to fetch files associated with a specific comparison ID.
 * Uses React Query for data fetching and caching.
 * Automatically includes the access token in the request.
 *
 * @param {string} comparisonId - The ID of the comparison to fetch files for
 * @returns {Object} Query result object with the following properties:
 * @returns {boolean} .isLoading - Whether the query is in progress
 * @returns {boolean} .isError - Whether the query encountered an error
 * @returns {Object} .error - Error object if the query failed
 * @returns {boolean} .isSuccess - Whether the query was successful
 * @returns {Object} .data - The files data if the query was successful
 */
const useFilesByComparison = (comparisonId) => {
  const accessTokenPromise = useAccessToken().getToken();

  return useQuery({
    queryKey: ["jobFiles", comparisonId],
    queryFn: async () => {
      if (!comparisonId) {
        return undefined;
      }
      const accessToken = await accessTokenPromise;
      return fetchFilesByComparisonId(comparisonId, accessToken);
    },
    enabled: !!comparisonId,
  });
};

export default useFilesByComparison;
