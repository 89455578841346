import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            p: 3,
            border: "1px solid #ff1744",
            borderRadius: 1,
            bgcolor: "#ffebee",
          }}
        >
          <Typography variant="h6" color="error">
            Something went wrong loading the grid.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Please try refreshing the page.
          </Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
