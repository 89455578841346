import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { headerStyles } from "../styles/layoutStyles";
import ButtonContainer from "../components/ButtonContainer";
import LogoContainer from "../components/LogoContainer";
import { useIsAuthenticated } from "@azure/msal-react";
import UserMenu from "../components/UserMenu";

const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  console.log("isAuthenticated", isAuthenticated);
  const buttons = [];

  return (
    <AppBar position="static" sx={headerStyles.appBar}>
      <Toolbar sx={headerStyles.toolbar}>
        <LogoContainer logoStyles={headerStyles.logoStyles} />

        <ButtonContainer buttons={buttons} buttonContainerStyles={headerStyles.buttonContainerStyles} />
        {isAuthenticated ? <UserMenu /> : <></>}
      </Toolbar>
    </AppBar>
  );
};
export default Header;
