import { SignInButton } from "../components/SignInButton";
import { loginRequest } from "../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Login() {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  console.log("instance", instance);

  useEffect(() => {
    if (isAuthenticated && inProgress === "none") {
      navigate("/");
    } else if (inProgress === "none") {
      instance.loginRedirect(loginRequest);
    }
  }, [instance, navigate, inProgress]);
  return (
    <div>
      <div>This application needs you to be logged in to use it.</div>
      <SignInButton />
    </div>
  );
}

export default Login;
