import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Box, InputLabel, FormControl, Select, MenuItem, TextField, FormHelperText } from "@mui/material";
import FileSelector from "../components/FileSelector";
import LobSelect from "../components/LobSelect";
import IssuerSelect from "../components/IssuerSelect";

/**
 * FormRowGenerator is a dynamic form field component that renders different types of form inputs
 * based on the provided field configuration. It integrates with react-hook-form for form handling
 * and Material-UI for the UI components.
 *
 * @component
 * @param {Object} props
 * @param {Object} props.thisField - Configuration object for the form field
 * @param {string} props.thisField.name - The name/identifier of the form field
 * @param {string} props.thisField.label - The display label for the form field
 * @param {string} props.thisField.type - Type of the form field ('file', 'select', etc.)
 * @param {*} [props.thisField.defaultValue] - Default value for the field
 * @param {boolean} [props.thisField.required] - Whether the field is required
 * @param {Array} [props.thisField.options] - Array of options for select-type fields
 * @param {Object} props.control - react-hook-form's control object for form state management
 * @param {Function} props.control.register - Function to register form fields
 * @param {Function} props.control.unregister - Function to unregister form fields
 * @param {Function} props.control.getValues - Function to get form values
 * @param {Function} props.control.setValue - Function to set form values
 * @param {Function} props.control.watch - Function to watch form values
 * @param {Object} props.errors - Form validation errors object
 * @param {string} [props.errors.message] - Error message if validation fails
 * @param {string} [props.errors.type] - Type of validation error
 *
 * @returns {React.ReactElement} A form field component based on the thisField configuration
 *
 * @example
 * // Example usage for a file input
 * <FormRowGenerator
 *   thisField={{
 *     name: "document",
 *     label: "Upload Document",
 *     type: "file",
 *     required: true
 *   }}
 *   control={formControl}
 *   errors={formErrors}
 * />
 *
 * @example
 * // Example usage for a select input
 * <FormRowGenerator
 *   thisField={{
 *     name: "category",
 *     label: "Select Category",
 *     type: "select",
 *     options: ["Option 1", "Option 2"],
 *     required: true
 *   }}
 *   control={formControl}
 *   errors={formErrors}
 * />
 */
function FormRowGenerator({ thisField, control, errors }) {
  const fieldError = errors?.[thisField.name];

  if (thisField.type === "file") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth error={!!fieldError}>
              <FileSelector
                {...field}
                label={`${thisField.label}${thisField.required ? " *" : ""}`}
                variant="outlined"
                fullWidth
                error={!!fieldError}
              />
              {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Box>
    );
  }

  if (thisField.type === "select") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth error={!!fieldError}>
              <InputLabel id="select-label" error={!!fieldError}>
                {thisField.label}
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                {...field}
                label={thisField.label}
                variant="outlined"
                fullWidth
                required={thisField.required}
                error={!!fieldError}
              >
                {thisField.options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Box>
    );
  }

  if (thisField.type === "lob") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <FormControl fullWidth error={!!fieldError}>
              <InputLabel id="lob-select-label" error={!!fieldError}>
                {thisField.label} *
              </InputLabel>
              <LobSelect {...field} required={thisField.required} label={thisField.label} error={!!fieldError} />
              {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Box>
    );
  }

  if (thisField.type === "issuer") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <FormControl fullWidth error={!!fieldError}>
              <InputLabel id="issuer-select-label" error={!!fieldError}>
                {thisField.label} *
              </InputLabel>
              <IssuerSelect {...field} required={thisField.required} label={thisField.label} error={!!fieldError} />
              {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Controller
        name={thisField.name}
        control={control}
        rules={{ required: thisField.required }}
        render={({ field }) => (
          <TextField
            {...field}
            label={`${thisField.label}`}
            variant="outlined"
            fullWidth
            required={thisField.required}
            error={!!fieldError}
            helperText={fieldError?.message}
            disabled={thisField.disabled}
          />
        )}
      />
    </Box>
  );
}

FormRowGenerator.propTypes = {
  thisField: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.any,
    required: PropTypes.bool,
    options: PropTypes.array,
    disabled: PropTypes.bool,
  }),
  control: PropTypes.shape({
    register: PropTypes.func,
    unregister: PropTypes.func,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
  }),
  errors: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default FormRowGenerator;
