import { useQuery } from "@tanstack/react-query";
import fetchScenarios from "../services/get-scenarios-api";
import useAccessToken from "./useAccessToken";

export default function useActionButtonPopulator() {
  const accessTokenPromise = useAccessToken().getToken();

  return useQuery({
    queryKey: ["scenarios"],
    queryFn: async () => {
      const accessToken = await accessTokenPromise;
      return fetchScenarios(accessToken);
    },
  });
}
