import mockData from "./mocks/job-comparison-results-by-comparisonid";
import getEnvVars from "../utils/getEnvVars";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

const fetchFilesByComparisonId = async (comparisonId, token = "") => {
  if (REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData.data);
  }
  const response = await fetch(
    `${REACT_APP_BACKEND_URL}api/job-comparison-results-by-comparisonid?comparison_id=${comparisonId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data.data;
};

export default fetchFilesByComparisonId;
