import React, { useState } from "react";
import { Box, IconButton, Menu, Typography, Divider } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { SignOutButton } from "./SignOutButton";
import { useMsal } from "@azure/msal-react";

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { accounts } = useMsal();
  const userAccount = accounts[0];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {userAccount?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {userAccount?.username}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 1, display: "flex", justifyContent: "flex-end" }}>
          <SignOutButton />
        </Box>
      </Menu>
    </Box>
  );
};

export default UserMenu;
