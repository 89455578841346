import { useQuery } from "@tanstack/react-query";
import getResultsByComparisonId from "../services/get-results-by-comparisonId-api";
import useAccessToken from "./useAccessToken";

/**
 * Custom hook to fetch results for a specific comparison ID.
 * Uses React Query for data fetching and caching.
 * Automatically includes the access token in the request.
 * Returns undefined if no comparisonId is provided.
 *
 * @param {string} comparisonId - The ID of the comparison to fetch results for
 * @returns {Object} Query result object with the following properties:
 * @returns {boolean} .isLoading - Whether the query is in progress
 * @returns {boolean} .isError - Whether the query encountered an error
 * @returns {Object} .error - Error object if the query failed
 * @returns {boolean} .isSuccess - Whether the query was successful
 * @returns {Object} .data - The results data if the query was successful
 */
const useResultsById = (comparisonId) => {
  const accessTokenPromise = useAccessToken().getToken();

  return useQuery({
    queryKey: ["result", comparisonId],
    queryFn: async () => {
      if (!comparisonId) {
        return undefined;
      }
      const accessToken = await accessTokenPromise;
      return getResultsByComparisonId(comparisonId, accessToken);
    },
    enabled: !!comparisonId,
  });
};
export default useResultsById;
