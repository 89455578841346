import { useQuery } from "@tanstack/react-query";
import fetchFilesByFileId from "../services/get-file-by-fileid-api";
import useAccessToken from "./useAccessToken";

/**
 * Custom hook to load a document based on a file ID.
 * @param {string} fileId - The ID of the file to load.
 * @returns {object} The loaded document data or undefined if the file ID is not provided.
 */
const useLoadDocument = (fileId) => {
  console.log("useLoadDocument.fileId", fileId);
  const accessTokenPromise = useAccessToken().getToken();

  return useQuery({
    queryKey: ["documentLoader", fileId],
    queryFn: async () => {
      if (!fileId) {
        return undefined;
      }
      const accessToken = await accessTokenPromise;
      return fetchFilesByFileId(fileId, accessToken);
    },
    enabled: !!fileId,
  });
};

export default useLoadDocument;
