import React, { useEffect, useMemo, useState } from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { tokenizeSearchTerm } from "../utils/pdfUtils";
import { Worker } from "@react-pdf-viewer/core";

/**
 * Converts a base64 encoded PDF string to a Blob object.
 * @param {string} data - The base64 encoded PDF data.
 * @returns {Blob} A Blob object containing the PDF data.
 */
const base64toBlob = (data) => {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: "application/pdf" });
};

/**
 * A React component that renders a PDF viewer with search, navigation, and highlighting capabilities.
 * This component uses the @react-pdf-viewer library to provide a rich PDF viewing experience.
 *
 * Features:
 * - PDF rendering with zoom controls
 * - Page navigation
 * - Text search with highlighting
 * - Automatic page jumping to search results
 *
 * @component
 * @param {Object} props
 * @param {string} props.pdfUrl - Base64 encoded PDF data to be displayed
 * @param {string} [props.searchTerm] - Term to search for within the PDF
 * @param {number} [props.targetPages] - Specific page number to navigate to (0-based index)
 * @param {string} [props.zoomLevel] - Zoom level setting ('width' for page width, otherwise fits to page)
 *
 * @example
 * <PdfViewer
 *   pdfUrl="base64EncodedPdfData"
 *   searchTerm="example"
 *   targetPages={2}
 *   zoomLevel="width"
 * />
 */
const PdfViewer = ({ pdfUrl, searchTerm, targetPages, isMatch }) => {
  const [currentTokenizedSearch, setCurrentTokenizedSearch] = useState(tokenizeSearchTerm(searchTerm));

  const searchPluginInstance = searchPlugin({
    enableShortcuts: false,
    currentTokenizedSearch,
    renderHighlights: React.useCallback(
      (renderProps) => (
        <>
          {renderProps.highlightAreas.map((area, index) => (
            <div
              className="rpv-search__highlight"
              key={`${area.pageIndex}-${index}`}
              data-index={index}
              style={{
                ...renderProps.getCssProperties(area),
                position: "absolute",
                backgroundColor: isMatch ? "rgba(0, 255, 0, 0.3)" : "rgba(255,0,0,0.3)",
              }}
            ></div>
          ))}
        </>
      ),
      [isMatch]
    ),
  });
  const paginationPluginInstance = pageNavigationPlugin();

  useEffect(() => {
    setCurrentTokenizedSearch(tokenizeSearchTerm(searchTerm));
    searchPluginInstance.setTargetPages((tp) => (tp || {}).pageIndex === targetPages);
  }, [searchTerm, targetPages]);

  useEffect(() => {
    if (typeof targetPages === "number") {
      searchPluginInstance.clearHighlights();
      console.log("Trying to highlight", currentTokenizedSearch);
      searchPluginInstance.highlight(currentTokenizedSearch).then((matches) => {
        console.log("We found these matches", matches);
        if (matches.length > 3 && currentTokenizedSearch.length > 1) {
          // Find the shortest search term
          const shortestTermIndex = currentTokenizedSearch.reduce(
            (minIndex, current, idx, arr) => (current.length < arr[minIndex].length ? idx : minIndex),
            0
          );
          // Remove the shortest term and update the search
          const newTokenizedSearch = currentTokenizedSearch.filter((_, index) => index !== shortestTermIndex);
          console.log("Shorter search term found", newTokenizedSearch, currentTokenizedSearch);

          if (newTokenizedSearch.length < currentTokenizedSearch.length && newTokenizedSearch.length > 0) {
            console.log("Shorter search term found and highlighted", newTokenizedSearch, currentTokenizedSearch);
            setCurrentTokenizedSearch(newTokenizedSearch);
          }
        } else if (matches.length === 0) {
          const newTokenizedSearch = currentTokenizedSearch.map((i) => tokenizeSearchTerm(i)).flat();
          if (currentTokenizedSearch.length !== newTokenizedSearch.length) {
            setCurrentTokenizedSearch(newTokenizedSearch);
          } else {
            paginationPluginInstance.jumpToPage(targetPages);
          }
        }
      });
    }
  }, [targetPages, currentTokenizedSearch]);

  const url = useMemo(() => {
    const blob = base64toBlob(pdfUrl);
    return URL.createObjectURL(blob);
  }, [pdfUrl]);

  if (!pdfUrl) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress data-testid="loading-indicator" />
      </Box>
    );
  }

  const plugins = [searchPluginInstance, paginationPluginInstance];
  return (
    <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
      <Viewer
        fileUrl={url}
        defaultScale={SpecialZoomLevel.PageWidth}
        plugins={plugins}
        initialPage={targetPages || 1}
      />
    </Worker>
  );
};

PdfViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  targetPages: PropTypes.number,
  isMatch: PropTypes.bool,
};

PdfViewer.displayName = "PdfViewer";

export default PdfViewer;
