import { useQuery } from "@tanstack/react-query";
import getLobList from "../services/get-lob-list";
import useAccessToken from "./useAccessToken";

/**
 * Custom hook to fetch and populate the list of LOBs (Lines of Business).
 * Uses React Query for data fetching and caching.
 * Automatically includes the access token in the request.
 *
 * @returns {Object} Query result object with the following properties:
 * @returns {boolean} .isLoading - Whether the query is in progress
 * @returns {boolean} .isError - Whether the query encountered an error
 * @returns {Object} .error - Error object if the query failed
 * @returns {boolean} .isSuccess - Whether the query was successful
 * @returns {Object} .data - The LOB list data if the query was successful
 */
export default function useLobPopulator() {
  const accessTokenPromise = useAccessToken().getToken();

  return useQuery({
    queryKey: ["lobList"],
    queryFn: async () => {
      const accessToken = await accessTokenPromise;
      return getLobList(accessToken);
    },
  });
}
