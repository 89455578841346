import { useMutation } from "@tanstack/react-query";
import getEnvVars from "../utils/getEnvVars";
import useAccessToken from "./useAccessToken";

const useJobCreationMutation = () => {
  const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();
  const accessTokenPromise = useAccessToken().getToken();

  return useMutation({
    mutationFn: async (newTodo) => {
      if (REACT_APP_USE_MOCKED_BACKEND === "true") {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              status: "error",
              message: "job created successfully.",
              data: newTodo,
            });
          }, 3000);
        });
      }
      const formData = new FormData();
      const submitable = {
        ...newTodo,
      };
      submitable.files = [];
      newTodo.files.forEach((fRow) => {
        console.log("fRow", fRow);
        formData.append("files", fRow.file_content);
        submitable.files.push({
          file_name: fRow.file_name,
          scenario_file_id: fRow.scenario_file_id,
        });
      });
      formData.append("job_data", JSON.stringify(submitable));
      console.log("formData", formData);

      const accessToken = await accessTokenPromise;
      return fetch(`${REACT_APP_BACKEND_URL}api/createjob`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      }).then((response) => response.json());
    },
  });
};

export default useJobCreationMutation;
